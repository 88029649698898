import '../scss/main.scss'; 
import { hamburger } from './components/hamburger';
import { smoothScroll } from './components/smoothScroll';
import { menuReveal } from './components/menuReveal';
import { navColor } from './components/navColor';

hamburger();
smoothScroll();
menuReveal();
navColor();
