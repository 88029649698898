export const navColor = () => {
    window.addEventListener('scroll', () => {
        let distanceFromTop = window.scrollY;
        let nav = document.querySelector(".nav");
        if(distanceFromTop > 100) {
            nav.classList.add("nav--white");
        }
        else {
            nav.classList.remove("nav--white");
        }
    });

   }
   